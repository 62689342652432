<template>
  <div>
    <div v-for="(issue, index) in issues" :key="issue.id">
      <calendar-issue
        :class="[{ first_of_all: (index === 0) & (level === 0) }]"
        :style="'margin-left: ' + level * 20 + 'px'"
        :issue="issue"
        :resourceId="resourceId"
        @issueClick="issueClick"
      />
      <calendar-issue-group
        v-if="issue.children.length"
        :issues="issue.children"
        :level="level + 1"
        :resourceId="resourceId"
        @issueClick="issueClick"
      />
    </div>
  </div>
</template>

<script>
import CalendarIssueGroup from "./CalendarIssueGroup.vue";
import CalendarIssue from "./CalendarIssue.vue";

export default {
  name: "CalendarIssueGroup",
  components: {
    CalendarIssueGroup,
    CalendarIssue,
  },
  props: {
    issues: Array,
    level: {
      type: Number,
      default: 0,
    },
    resourceId: Number,
  },
  methods: {
    issueClick(issue) {
      this.$emit("issueClick", issue);
    },
  },
};
</script>
