











function hourMinute2Hours(hour, minute) {
    return parseInt(hour) + (parseInt(minute) / 60);
}
function hours2HourMinute(hours) {
    return hours ? [Math.floor(hours), Math.round((hours % 1) * 60)] : [0, 0];
}

export default {
    props: {
        value: {},
        error: {},
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            hour: 0,
            minute: 0,
        };
    },
    methods: {
        hoursOutOfRange(hours) {
            return hours < parseInt(this.$refs.hour.min)
                || hours > parseInt(this.$refs.hour.max)
        },
        updateHours(hour, minute) {
            const hours = hourMinute2Hours(hour, minute)
            if (this.hoursOutOfRange(hours)) return this.$refs.minute.value = 0;
            this.$emit('input', hours);
        },
        focus() {
            const hourInput = this.$refs.hour;
            hourInput.focus();
            // v-- Not supported --v
            // hourInput.setSelectionRange(0, hourInput.value.length);
        },
        updateHour(e) {
            this.updateHours(e.target.value, this.minute);
        },
        updateMinute(e) {
            this.updateHours(this.hour, e.target.value);
        },
        updateThisHourMinute() {
            [this.hour, this.minute] = hours2HourMinute(this.value);
        }
    },
    watch: {
        value() {
            this.updateThisHourMinute();
        },
    },
    mounted() {
        this.updateThisHourMinute();
    }
};
