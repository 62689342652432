var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issue",attrs:{"data-id":_vm.issue.id},on:{"click":_vm.issueClick}},[_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{class:[
          'issue_label',
          'mr-auto',
          {
            assigned_mine: _vm.isAssignedToMe(_vm.issue),
            assigned_not: _vm.isNotAssigned(_vm.issue),
            assigned_other: _vm.isAssignedToOther(_vm.issue),
          } ]},[_vm._v(" "+_vm._s(_vm.issue.label)+" ")]),(_vm.issue.data.next_spent_on)?_c('icon-calendar',{ref:"nextEntry",staticClass:"float-right"}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }