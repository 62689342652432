import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import dayjs from "dayjs";
import tippy from "tippy.js";
import * as Config from "@/config/constants";
import * as Redmine from "@/config/redmine-constants";
import getEnv from '@/utils/env';
import {
    filterResourceByName,
    getAssignedProjects,
    normalizedContains,
    timeEntryToInput,
} from '@/app/helpers';
import {
    Issue,
    PublicHoliday,
    TimeEntry,
    TimeModalSelection as Selection
} from '@/app/types/interfaces';
import {
    getCrudUpdatesForDelta,
    getEventColor,
    loadProjectFilters,
    populateTimes,
    renderEvent,
    saveProjectFilters,
    // sameDay,
} from '@/app/calendar-helpers';
import {
    addMissingParentsToList,
    // formatK,
    // getDPPe,
    // getEntrySpeed,
    toTree
} from '../tree/tree-helpers';
import bookmarkMixin from "@/utils/bookmark-mixin";
import CalendarIssueGroup from "./CalendarIssueGroup.vue";
import IssueModal from "./IssueModal.vue";
import IssuePrio from './IssuePrio.vue';
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import GroupSelect from "@/views/common/GroupSelect.vue";
import IconTree from "@/icons/sitemap-solid.svg";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";
import TimePicker from '@/views/time-modal/TimePicker.vue';

// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");


const smallScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 800;
const initialView = smallScreen ? 'timeGridDay' : 'timeGridWeek';
let fcApi = null;

function refreshTimeEntries() {
    tips.forEach((t) => t.destroy());
    tips.length = 0;
    fcApi.getEventSourceById('time-entries').refetch();
    fcApi.render();
}

const defaultSelection = (): Selection => ({
    timeEntryId: null,
    projectId: null,
    resourceId: null,
    issueId: null,
    date: null,
    startTime: null,
});


const defaultFilters = () => ({
    assigned: 'mine',
    statuses: Redmine.DEFAULT_STATUSES_FOR_ASSIGNMENT,
    priorities: Redmine.ISSUE_PRIORITIES.map(prio => prio.id),
    search: null,
    notStarted: false,
    version: null,
});


const multipleIdsComparer = (a, b) =>
    // a.map(o => o.id).sort().join('')
    // !== b.map(o => o.id).sort().join('');
    a.sort().join('') !== b.sort().join('');
const filterComparers = {
    statuses: multipleIdsComparer,
    priorities: multipleIdsComparer,
}


function hourToTime(hour: string) {
    return hour.padStart(2, '0') + ':00';
}
const defaultSlotMinHour = localStorage.getItem('resource_manager_slot_min_hour') || '8';
const defaultSlotMaxHour = localStorage.getItem('resource_manager_slot_max_hour') || '20';
const localShowWeekends = localStorage.getItem('resource_manager_show_weekends');
const defaultShowWeekends = localShowWeekends !== null ? JSON.parse(localShowWeekends) : true;


let latestMousedownEvent;
let eventSource;


const tips = [];


export default {
    name: 'Calendar',
    mixins: [bookmarkMixin('calendar')],
    components: {
        FullCalendar,
        Multiselect,
        CalendarIssueGroup,
        IssueModal,
        IssuePrio,
        TimeModal,
        ResourceSelect,
        GroupSelect,
        IconTree,
        IconRefresh,
        TimePicker,
    },
    props: ['timeEntryId'],
    data() {
        return {
            smallScreen,
            calendarOptions: {
                plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
                initialView,
                height: 'auto',
                headerToolbar: false,
                selectable: true,
                droppable: true,
                editable: true,
                allDaySlot: false,
                slotMinTime: hourToTime(defaultSlotMinHour),
                slotMaxTime: hourToTime(defaultSlotMaxHour),
                expandRows: true,
                snapDuration: '00:15:00',
                eventSources: [
                    {
                        id: 'time-entries',
                        events: this.getTimeEntries,
                    },
                    {
                        id: 'public-holidays',
                        events: this.getPublicHolidays,
                        color: "#ddd",
                    },
                ],
                datesSet: this.datesSet,
                eventClick: this.eventClick,
                select: this.selectTime,
                drop: this.dropIssue,
                eventClassNames: this.eventClassNames,
                eventContent: renderEvent,
                eventDidMount: this.eventDidMount,
                eventDrop: this.eventDrop,
                eventResize: this.eventResize,
                eventDragStart: this.eventDragStart,
                weekends: defaultShowWeekends,
                stickyHeaderDates: false,
                eventMinHeight: 10,
                dayMaxEvents: true,
                nowIndicator: true,
                firstDay: 1,
                slotLabelFormat: {
                    hour: 'numeric',
                    hour12: false,
                },
                dayHeaderContent({ date, view }) {
                    const format = view.type === 'timeGridWeek' ? 'ddd DD/MM' : 'dddd';
                    return dayjs(date).locale('en').format(format);
                },
            },
            view: initialView,
            resourcesFiltered: [],
            resource: null,
            project: null,
            issues: [],
            issuesFiltered: [],
            selection: defaultSelection(),
            showNotes: false,
            notes: '',
            selectedNodeId: null,
            issueCountByProject: [],
            includeUnassigned: false,
            showFilters: false,
            showWeekends: defaultShowWeekends,
            slotMinHour: defaultSlotMinHour,
            slotMaxHour: defaultSlotMaxHour,
            priorities: Redmine.ISSUE_PRIORITIES,
            filters: defaultFilters(),
            title: '',
            // colorThemes: Config.COLOR_THEMES,
            // colorTheme: '',
            versions: [],
            shiftDragging: false,
            group: null,
            loadingTimeEntry: false,
            timeEntries: [],
            activeStart: null,
            activeEnd: null,
        };
    },

    computed: {
        // @ts-ignore
        ...mapGetters({
            user: 'Resource/auth/user',
            resources: 'Resource/list/resources',
            projects: 'Project/list/projects',
            statuses: 'Enumeration/list/statuses',
            groups: 'Group/edit/groups',
        }),
        resourceProjects() {
            const resourceIds = [this.resource?.id || this.user.id];
            return (getAssignedProjects(this.projects, resourceIds));
        },
        showTimeModal() {
            return !!Object.values(this.selection).find(param => param);
        },
        issuesTree() {
            if (!this.issuesFiltered.length) return [];
            return toTree(this.issuesFiltered)[0].children;
        },
        projectsWithAssignedIssues() {
            if (!this.projects.length) return [];
            return this.issueCountByProject.map(issCount => {
                issCount.name = this.projects.find(project => project.id === issCount.id).name;
                return issCount;
            }).sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
        },
        areActiveFilters() {
            const dFilters = defaultFilters();
            return Object.keys(this.filters).some(key => {
                const a = this.filters[key];
                const b = dFilters[key];
                return filterComparers[key] ? filterComparers[key](a, b) : a !== b;
            });
        },
        groupResources() {
            if (!this.group) return [];
            const userIds = this.group.userIds.map(id => parseInt(id))
            return this.resources.filter(resource => userIds.includes(resource.id));
        },
        issuesNb() {
            if (!this.issuesFiltered.length) return 0;
            return this.issuesFiltered.filter((issue) => issue.matching).length;
        },
        selectedVersionLabel() {
            if (!this.filters.version || !this.versions.length) return null;
            return this.versionLabel(this.filters.version);
        },
        indexedVersions() {
            if (!this.versions.length) return {};
            const indexedVersions = this.versions.reduce((acc, version) => {
                acc[version.id] = version;
                return acc;
            }, {});
            return indexedVersions;
        },
        showedTimeentries() {
            let timeEntries = this.timeEntries;
            if (this.activeStart && this.activeStart) {
                timeEntries = this.timeEntries.filter((timeEntry: TimeEntry) => {
                    const date = dayjs(timeEntry.spent_on).hour(12);
                    return date.isAfter(this.activeStart) && date.isBefore(this.activeEnd);
                });
            }
            return timeEntries;
        },
        totalHours() {
            return Math.round(this.showedTimeentries.reduce((acc, timeEntry) => acc + timeEntry.hours, 0));
        },
        topDays() {
            const topDays = [];
            if (this.view !== 'timeGridWeek') return topDays;
            let curDay = dayjs(this.activeStart);
            while (curDay.isBefore(this.activeEnd)) {
                const dayEntries = this.showedTimeentries
                    .filter((timeEntry: TimeEntry) => curDay.isSame(timeEntry.spent_on, 'day'));
                topDays.push({
                    id: curDay.date(),
                    hours: Math.round(dayEntries.reduce((acc, timeEntry: TimeEntry) => acc + timeEntry.hours, 0) * 10) / 10,
                    real: dayEntries.length ? dayEntries.reduce((acc, timeEntry: TimeEntry) => acc &= +!timeEntry.future_time, true) : false,
                });
                curDay = curDay.add(1, 'day');
            }
            return topDays;
        },
    },

    methods: {
        getTimeEntries(fetchInfo, successCallback, failureCallback) {
            if (this.loadingTimeEntry) return;
            const resourceIds = this.resource?.id || this.user.id;
            const toDate = dayjs(fetchInfo.end).subtract(1, 'day'); // <- Timezone issue (only works for >GMT TZ)
            let payload = {
                from: dayjs(fetchInfo.start).format(Config.DATE_FORMAT),
                to: toDate.format(Config.DATE_FORMAT),
                resourceIds,
            };
            // if (this.colorTheme) {
            //     payload['issueDetail'] = '';
            // }
            // this.timeEntries = [];
            this.$store.dispatch('TimeEntry/list/getListArray', payload).then(({ timeEntries, issues }) => {
                this.timeEntries = timeEntries;
                const events = timeEntries.map((timeEntry: TimeEntry) => {
                    const issue = issues.length ? issues.find(issue => timeEntry.issue_id === issue.id) : null;
                    return {
                        id: timeEntry.id,
                        title: timeEntry.issue_name,
                        timeEntry: timeEntry,
                        editable: !timeEntry.locked,
                        color: getEventColor(timeEntry, issue, null),
                        // color: getEventColor(timeEntry, issue, this.colorTheme),
                        issue,
                    };
                });
                populateTimes(events);
                successCallback(events);
            });
            return;
        },

        getPublicHolidays(fetchInfo, successCallback, failureCallback) {
            if (this.loadingTimeEntry) return;
            const resourceIds = this.resource?.id || this.user.id;
            let payload = {
                from: dayjs(fetchInfo.start).format(Config.DATE_FORMAT),
                to: dayjs(fetchInfo.end).format(Config.DATE_FORMAT),
                resourceIds,
            };
            this.$store.dispatch('TimeEntry/list/getPublicHolidays', payload).then((data: any) => {
                const events = data.public_holidays
                    .filter((publicHoliday: PublicHoliday) => {
                        if (this.resource.country !== publicHoliday.country) return false;
                        const dayOfWeek = dayjs(publicHoliday.date).day();
                        if (dayOfWeek == 6 || dayOfWeek == 0) return false;
                        return true;
                    })
                    .map((publicHoliday: PublicHoliday) => {
                        return {
                            date: publicHoliday.date,
                            title: publicHoliday.name,
                            display: 'background',
                        };
                    });
                successCallback(events);
            });
        },

        refreshTimeEntries,

        datesSet(dateInfo) {
            this.title = dateInfo.view.getCurrentData().viewTitle;
            this.activeStart = dateInfo.start;
            this.activeEnd = dateInfo.end;
        },

        eventClassNames({ event }) {
            if (event.display === 'background') return;
            if (!event.extendedProps.timeEntry) return;
            const timeEntry: TimeEntry = event.extendedProps.timeEntry;
            const classNames = [];
            if (this.project && timeEntry.project_id !== this.project.id) classNames.push('opacity7');
            if (timeEntry.locked === 1) classNames.push('locked');
            if (timeEntry.locked === 2) classNames.push('unlocked');
            return classNames;
        },

        eventDidMount({ el, event, isMirror }) {
            if (event.display === 'background') return;
            if (!event.extendedProps.timeEntry) return;
            if (isMirror) return;
            const entry: TimeEntry = event.extendedProps.timeEntry;
            const issue: Issue = event.extendedProps.issue;
            let content = entry.project ? `<b>${entry.project.name}</b>` : '<i>Closed</i>';
            content += `<br />${entry.issue_name}`;
            if (entry.comments && entry.comments !== '.' && entry.comments !== Redmine.DEFAULT_ISSUE_COMMENT) {
                content += `<br />Comments: <i>${entry.comments}</i>`;
            }
            // if (this.colorTheme?.id === Config.COLOR_THEME_EARLY_LATE_ENTRY) {
            //     if (Redmine.DPPEABLE_VERSION_TYPE.includes(issue.version_type)) {
            //         const speed = getEntrySpeed(entry, issue);
            //         content += `<div class="${speed < 1 ? 'text-danger' : 'text-success'}">Early/Late Entry: ${Math.round(speed * 100)}%</div>`;
            //     }
            // }
            // if (this.colorTheme?.id === Config.COLOR_THEME_EARLY_LATE_ISSUE) {
            //     let dppe;
            //     if (Redmine.DPPEABLE_VERSION_TYPE.includes(issue.version_type) && (dppe = getDPPe(issue))) {
            //         content += `<div class="${dppe > 0 ? 'text-danger' : 'text-success'}">Early/Late Task: ${formatK(dppe)}h</div>`;
            //     }
            // }
            if (!entry.locked) {
                content += '<div class="tooltip_foot_text">Shift + drag to copy<br />Shift + click to start a copy<br />Ctrl + click to show the task</div>';
            }
            const tip = tippy(el, { content });
            if (tips.indexOf(tip) === -1) tips.push(tip);
        },

        eventClick({ event, jsEvent }) {
            // if (event.extendedProps.timeEntry.locked) return;
            if (jsEvent.ctrlKey) return this.selectedNodeId = event.extendedProps.timeEntry.issue_id;
            if (jsEvent.shiftKey) this.selection.copy = true;
            this.selection.timeEntryId = event.extendedProps.timeEntry.id;
        },

        eventDragStart({ jsEvent }) {
            this.shiftDragging = jsEvent.shiftKey;
        },

        selectTime({ start, end, jsEvent }) {
            if (
                Math.abs(jsEvent.clientX - latestMousedownEvent.clientX) < 10
                && Math.abs(jsEvent.clientY - latestMousedownEvent.clientY) < 10
            ) {
                end = (new Date(start.getTime())).setHours(start.getHours() + 1);
            }
            const dateJs = dayjs(start);
            // const dateJs = dayjs.tz(start, Config.REDMINE_TIMEZONE);
            const selection = {
                resourceId: this.resource.id,
                projectId: this.project?.id,
                date: dateJs.format(Config.DATE_FORMAT),
                startTime: dateJs.format(Config.TIME_FORMAT),
                hours: (end - start) / 3600000,
            };
            this.selection = selection;
        },

        eventDrop({ event, revert, jsEvent, oldEvent }) { // Moving timeentry
            const originalTimeEntry: TimeEntry = event.extendedProps.timeEntry;
            const date = dayjs(event.start);
            // const date = dayjs.tz(event.start, Config.REDMINE_TIMEZONE);
            let timeEntry: any = {
                id: originalTimeEntry.id,
                date: date.format(Config.DATE_FORMAT),
                startTime: date.format(Config.TIME_FORMAT),
            };
            const promises = [];

            if (jsEvent.shiftKey || this.shiftDragging) { // Copying timeentry
                revert();
                this.shiftDragging = false;
                timeEntry = Object.assign(timeEntryToInput(originalTimeEntry), timeEntry);
                delete timeEntry.id;
                delete timeEntry.doneRatioBefore;
                delete timeEntry.doneRatioAfter;
                delete timeEntry.comment;
            } else {
                getCrudUpdatesForDelta(fcApi.getEvents(), event, oldEvent).forEach(crudUpdate => {
                    promises.push(this.$store.dispatch('Crud/upsertTimeEntry', crudUpdate));
                });
            }

            promises.push(this.$store.dispatch('Crud/upsertTimeEntry', timeEntry));
            Promise.all(promises).then(refreshTimeEntries).catch(revert);
        },

        eventResize({ event, oldEvent, revert }) {
            const timeEntry = {
                id: event.extendedProps.timeEntry.id,
                hours: (event.end - event.start) / 3600000,
            };
            const promises = [];
            promises.push(this.$store.dispatch('Crud/upsertTimeEntry', timeEntry));
            getCrudUpdatesForDelta(fcApi.getEvents(), event, oldEvent).forEach(crudUpdate => {
                promises.push(this.$store.dispatch('Crud/upsertTimeEntry', crudUpdate));
            });
            Promise.all(promises).then(refreshTimeEntries).catch(revert);
        },

        dropIssue({ draggedEl, date }) { // Creating timeentry
            const issue = this.issues.find(issue => issue.id == draggedEl.dataset['id']);
            const dateJs = dayjs(date);
            // const dateJs = dayjs.tz(date, Config.REDMINE_TIMEZONE);
            this.selection = {
                resourceId: this.resource.id,
                projectId: this.project.id,
                issueId: issue.id,
                date: dateJs.format(Config.DATE_FORMAT),
                startTime: dateJs.format(Config.TIME_FORMAT),
                add: true,
            }
        },

        // planIssue() {
        //     if (!this.selectedNodeId) return;
        //     this.selection = {
        //         resourceId: this.resource.id,
        //         projectId: this.project.id,
        //         issueId: this.selectedNodeId,
        //     };
        // },

        closeTimeModal() {
            this.selection = defaultSelection();
        },

        showIssueModal(node) {
            this.selectedNodeId = node.data.id;
        },

        closeIssueModal() {
            this.selectedNodeId = null;
        },

        // unselectTheme() {
        //     this.colorTheme = null;
        //     this.refreshTimeEntries();
        // },
        setSlotMinTime() {
            fcApi.setOption('slotMinTime', hourToTime(this.slotMinHour));
            localStorage.setItem('resource_manager_slot_min_hour', this.slotMinHour);
        },
        setSlotMaxTime() {
            fcApi.setOption('slotMaxTime', hourToTime(this.slotMaxHour));
            localStorage.setItem('resource_manager_slot_max_hour', this.slotMaxHour);
        },
        toggleWeekends() {
            fcApi.setOption('weekends', this.showWeekends);
            localStorage.setItem('resource_manager_show_weekends', this.showWeekends);
        },
        toggleView() {
            this.view = this.view === 'dayGridMonth' ? initialView : 'dayGridMonth';
            fcApi.setOption('height', this.view === 'dayGridMonth' ? null : 'auto');
            fcApi.changeView(this.view);
        },
        prevPeriod() {
            fcApi.prev();
        },
        today() {
            fcApi.today();
        },
        nextPeriod() {
            fcApi.next();
        },

        toggleNotes(toggle) {
            this.showNotes = typeof toggle === 'boolean' ? toggle : !this.showNotes;
            if (!this.showNotes) return;
            setTimeout(() => {
                this.$refs.notes.focus();
            }, 110);
        },

        submitNotes() {
            const payload = {
                // userId: this.resource.id,
                userId: this.user.id,
                user: {
                    notes: this.notes,
                },
            }
            this.$store.dispatch('Resource/edit/setExtra', payload).then(() => {
                this.toggleNotes(false);
            });
        },

        normalizeResource(resource) {
            return resource.firstname + ' ' + resource.lastname;
        },

        searchResources(search) {
            this.resourcesFiltered = search
                ? this.resources.filter(resource => filterResourceByName(resource, search))
                : this.resources;
        },

        selectResource() {
            this.project = null;
            // this.getNotes();
            this.getCountForResourceByProject();
            this.refreshEvents();
        },

        searchIssues() {
            const search = this.filters.search;
            if (!search) {
                this.issuesFiltered = this.issues;
                return;
            }
            const issuesFiltered = this.issues.filter(
                (issue) =>
                    normalizedContains(search, issue.subject) ||
                    search === issue.devops_id ||
                    search == issue.id
            );
            addMissingParentsToList(issuesFiltered, this.issues);
            this.issuesFiltered = issuesFiltered;
        },

        getNotes() {
            // const userId = this.resource.id;
            const userId = this.user.id;
            this.$store.dispatch('Resource/list/getNotes', userId).then((notes) => {
                this.notes = notes;
            });
        },

        getBookmark() {
            const bookmark: any = {
                resourceId: this.resource.id,
            };
            if (this.project) {
                bookmark.projectId = this.project.id
                bookmark.filters = this.filters;
            }
            return bookmark;
        },
        loadBookmark() {
            if (!this.bookmark) return;
            this.bookmarkLoading = this.bookmark;
            if (this.resource?.id !== this.bookmark.resourceId) {
                this.resource = this.resources.find(res => res.id === this.bookmark.resourceId);
                this.getCountForResourceByProject();
            }
            const promises = [];
            if (this.bookmark.projectId) {
                promises.push(this.pickProject(this.bookmark.projectId));
            }
            Promise.all(promises).then(() => {
                this.bookmarkLoading = null;
            });
        },

        unsubscribeMercure() {
            if (eventSource) eventSource.close();
        },
        subscribeMercure() {
            if (!Config.ENABLE_SSE) return;
            this.unsubscribeMercure();
            const url = new URL(getEnv('VUE_APP_MERCURE_PUBLIC_URL'));
            url.searchParams.append('topic', `resource(${this.resource.id})`);
            //@ts-ignore
            eventSource = new EventSource(url, { withCredentials: true });
            eventSource.onmessage = this.gotAMessageFromMercure;
        },

        gotAMessageFromMercure(e) {
            if (this._inactive) return;
            const message = JSON.parse(e.data);
            if (message.author == this.user.id) return;
            const messageDate = dayjs(message.date);
            if (messageDate.isBefore(this.activeStart) || messageDate.isAfter(this.activeEnd)) return;
            refreshTimeEntries();
        },

        getCountForResourceByProject() {
            if (this.loadingTimeEntry) return;
            const payload = {
                resourceId: this.resource.id,
            };
            this.$store.dispatch('Issue/list/getListCountForResource', payload).then((issueCountByProject) => {
                this.issueCountByProject = issueCountByProject;
            });
        },

        pickProject(projectId) {
            this.project = this.projects.find(project => project.id == projectId);
            this.selectProject();
        },

        selectProject() {
            this.render();
            if (this.project) {
                this.filters = this.bookmarkLoading?.filters
                    || loadProjectFilters(this.project.id)
                    || defaultFilters()
                    ;
            }
            this.refreshIssues();
            if (!this.project) return Promise.resolve();
            return this.$store.dispatch("Issue/list/getVersions", this.project.id).then(versions => {
                this.versions = [{ id: -1, name: 'Sans version', status: 'closed' }].concat(versions);
            });
        },

        unselectProject() {
            this.project = null;
            this.selectProject();
        },

        refreshIssues() {
            if (!this.project) return this.issues = [];
            saveProjectFilters(this.project.id, this.areActiveFilters ? this.filters : null);
            const params: any = {
                projectId: this.project.id,
                assigned: this.filters.assigned,
                statusIds: this.filters.statuses.join(','),
                priorityIds: this.filters.priorities.join(','),
                versionId: this.filters.version,
            };
            if (this.filters.notStarted) {
                params.notStarted = '';
            }
            const payload: any = {
                resourceId: this.resource.id,
                params,
            };
            this.$store.dispatch('Issue/list/getListForResource', payload).then((issues) => {
                this.issues = issues;
                this.issuesFiltered = issues;
            });
        },

        refreshEvents() {
            fcApi.refetchEvents();
        },

        refreshAll() {
            this.refreshIssues();
            this.getCountForResourceByProject();
            refreshTimeEntries();
        },

        render() {
            fcApi.render();
        },

        resetFilters() {
            this.filters = defaultFilters();
            this.refreshIssues();
        },

        loadTimeEntry() {
            this.selection.timeEntryId = this.timeEntryId;
            if (this.timeEntryId) this.loadingTimeEntry = true;
        },

        timeEntryLoaded(timeEntry: TimeEntry) {
            if (!this.loadingTimeEntry) return;
            this.loadingTimeEntry = false;
            fcApi.gotoDate(timeEntry.spent_on);
            this.resource = this.resources.find(resource => resource.id === timeEntry.user_id);
            this.selectResource();
            this.project = { id: timeEntry.project_id, name: timeEntry.project.name };
            this.selectProject();
        },

        statusLabel(id) {
            return this.statuses.find(status => status.id === id).name;
        },
        priorityLabel(id) {
            return this.priorities.find(priority => priority.id === id).devops;
        },
        versionLabel(id) {
            const version = this.indexedVersions[id];
            if (!version) return '';
            return version.name;
        },

        fcMousedown(e) {
            latestMousedownEvent = e;
        },
    },

    watch: {
        issues() {
            this.searchIssues();
        },
        timeEntryId() {
            this.loadTimeEntry();
        },
        resource() {
            this.subscribeMercure();
        }
    },

    beforeRouteLeave(to, from, next) {
        // fcApi.destroy();
        // this.project = null;
        // this.filters.assigned = 'mine';
        // this.filters.search = null;
        // this.unsubscribeMercure();
        next();
    },
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         vm.subscribeMercure();
    //     });
    // },

    mounted() {
        fcApi = this.$refs.fullCalendar.getApi();
        this.resource = this.user;
        this.subscribeMercure();
        this.getCountForResourceByProject();

        const vm = this;
        setTimeout(() => {
            // Avoiding unexpected fullcalendar behavior
            vm.loadTimeEntry();
            vm.loadBookmark();
        }, 200);
        this.$store.dispatch('Enumeration/list/getStatuses');
        this.$store.dispatch('Project/list/getList');
        this.$store.dispatch('Group/edit/getList');
        this.resourcesFiltered = this.resources;
        this.getNotes();
        if (smallScreen) return;

        new Draggable(vm.$refs.issueTree, {
            itemSelector: '.issue',
            eventData: (eventEl) => {
                const issue = vm.issues.find(issue => issue.id == eventEl.dataset['id']);
                return {
                    title: issue.subject,
                    create: false,
                    color: Config.PRIMARY_COLOR,
                };
            },
        });
    },
};
