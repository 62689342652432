<template>
  <label
    :class="['issue_prio', 'flex-fill', { active: values.includes(value.id) }]"
  >
    <input
      class="hidden"
      type="checkbox"
      v-model="selected"
      :value="value.id"
    />
    {{ value.devops }}
  </label>
</template>

<script>
export default {
  props: {
    value: {},
    values: {},
  },
  model: { prop: "values" },
  computed: {
    selected: {
      get() {
        return this.values;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
