<template>
  <div class="issue" @click="issueClick" :data-id="issue.id">
    <div>
      <div class="d-flex align-items-center">
        <div
          :class="[
            'issue_label',
            'mr-auto',
            {
              assigned_mine: isAssignedToMe(issue),
              assigned_not: isNotAssigned(issue),
              assigned_other: isAssignedToOther(issue),
            },
          ]"
        >
          {{ issue.label }}
        </div>
        <icon-calendar
          class="float-right"
          ref="nextEntry"
          v-if="issue.data.next_spent_on"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import { mapGetters } from "vuex";
import IconCalendar from "@/icons/calendar-week-solid.svg";

export default {
  name: "CalendarIssue",
  components: {
    IconCalendar,
  },
  props: ["issue", "resourceId"],
  computed: {
    // @ts-ignore
    ...mapGetters({
      resourcesAll: "Resource/list/resourcesAll",
    }),
  },
  methods: {
    issueClick() {
      this.$emit("issueClick", this.issue);
    },
    isNotAssigned(issue) {
      return !issue.data.assigned_to_id;
    },
    isAssignedToMe(issue) {
      return issue.data.assigned_to_id === this.resourceId;
    },
    isAssignedToOther(issue) {
      return !!issue.data.assigned_to_id && !this.isAssignedToMe(issue);
    },
  },
  mounted() {
    if (this.issue.data.next_spent_on) {
      const user = this.resourcesAll.find(user => user.id === this.issue.data.next_user_id);
      const date = this.issue.data.next_spent_on;
      const el = this.$refs.nextEntry;
      // if (el._tippy) el._tippy.destroy();
      tippy(el, {
        boundary: 'viewport',
        content() {
          let htmlContent = '';
          htmlContent += '<div class="opacity6 mb-2">Next date</div>';
          htmlContent += `<div>${user.fullname}</div>`;
          htmlContent += `<div class="text-right">${date}</div>`;
          return htmlContent;
        },
      });
    }
  },
};
</script>
